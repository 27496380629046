<template>
  <section id="top" class="section_container pt-5">
    <default-home-side-menu :sidebar="sidebar" :menuItems="menuItems" />
    <v-toolbar light flat>
      <default-home-driwer-selector @click-driwer="clickDriwer($event)" />
      <default-home-logo />
      <v-spacer></v-spacer>
      <default-home-menu :menuItems="menuItems" />
      <v-spacer></v-spacer>
      <default-home-support
        :phone="phone"
        :email="email"
        :supportText="supportText"
      />
    </v-toolbar>
  </section>
</template>
<script>
export default {
  name: "HomeSectionTop",
  components: {
    DefaultHomeLogo: () => import("@/layouts/public/widgets/Logo.vue"),
    DefaultHomeDriwerSelector: () =>
      import("@/layouts/public/widgets/DriwerSelector.vue"),
    DefaultHomeMenu: () => import("@/layouts/public/widgets/Menu.vue"),
    DefaultHomeSideMenu: () => import("@/layouts/public/widgets/SideMenu.vue"),
    DefaultHomeSupport: () => import("@/layouts/public/widgets/Support.vue")
  },
  data() {
    return {
      sidebar: false,
      menuItems: [
        { title: "О проекте", path: "/#about" },
        { title: "Генеральная схема", path: "/#schema" },
        { title: "Документация", path: "/#docs" },
        // { title: "Новости", path: "/#news" },
        // { title: "Аналитика", path: "/#analytics" },
        { title: "Контакты", path: "/#contacts" }
      ],
      phone: "+7 (7172)574 482",
        email: "isun@iacng.kz",
      supportText: "Техническая поддержка"
    };
  },
  methods: {
    clickDriwer(driver) {
      this.sidebar = driver;
    }
  }
};
</script>
<style lang="scss" scoped>
.section_container {
  background-color: #ffffff;
  width: 70%;
  margin: 0 auto;
  height: 100px;
}
.section_link {
  color: white;
  text-decoration: none;
}
.section_link:hover {
  color: black;
}
</style>
